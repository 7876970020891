import Config from "@kaspernj/api-maker/src/config"
import I18nOnSteroids from "i18n-on-steroids"
import RaiseInBackground from "i18n-on-steroids/src/error-handlers/raise-in-background"
import translations from "./i18n-translations"

const i18n = new I18nOnSteroids({
  fallbacks: {
    da: ["da", "en", "no", "se"],
    en: ["en", "da", "se", "no"],
    no: ["no", "en", "da", "se"],
    se: ["se", "en", "no", "da"]
  }
})

i18n.scanObject(translations)

const locale = document.querySelector("html").getAttribute("lang")

i18n.setLocale(locale)
i18n.setLocaleOnStrftime()

if (window.RAILS_ENV == "production") {
  i18n.setErrorHandler(new RaiseInBackground())
}

Config.setI18n(i18n)

export default i18n
