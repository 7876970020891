import {Controller} from "stimulus"

export default class TrixFileAttachmentImageController extends Controller {
  connect() {
    console.log("TrixFileAttachmentImageController - removing width and height")

    this.element.removeAttribute("width")
    this.element.removeAttribute("height")

    if (!this.element.dataset.replacedLink &&
        !this.element.parentNode.parentNode.dataset.trixAttachment &&
        this.element.parentNode.tagName == "A" &&
        this.element.dataset.removeLink == "true") {
      this.replaceLinkWithImage()
    }
  }

  replaceLinkWithImage() {
    console.log("TrixFileAttachmentImageController - removing parent link")

    this.element.dataset.replacedLink = true

    const element = this.element
    const linkElement = this.element.parentNode

    linkElement.replaceWith(element)
  }
}
