import {createContext, useContext} from "react"
import {Modal} from "react-native"
import {parentElement} from "parent-element"
import {PortalHost} from "conjointment"

const events = new EventEmitter()
const shared = {
  active: {}
}
const ModalContext = createContext()
const useModals = () => {
  const active = useContext(ModalContext)
  const [modals, setModals] = useState(Object.assign({}, shared.active))

  const onModalAdded = useCallback(({id, data}) => {
    const newModals = Object.assign({}, modals)

    newModals[id] = data

    setModals(newModals)
  }, [modals])

  const onModalRemoved = useCallback(({id}) => {
    const newModals = Object.assign({}, modals)

    delete newModals[id]

    setModals(newModals)
  }, [])

  useEventEmitter(events, "modal-added", onModalAdded)
  useEventEmitter(events, "modal-removed", onModalRemoved)

  return {
    active,
    modals
  }
}

export {ModalContext, shared, useModals}

export default memo(shapeComponent(class ComponentsModal extends ShapeComponent {
  static defaultProps = {
    active: true
  }

  static propTypes = {
    active: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onBack: PropTypes.func,
    onRequestClose: PropTypes.func
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.modal"})

    this.cardRef = useRef()
    this.id = uuidv4()
    this.modalContextValue = useMemo(() => ({
      className: this.props.className,
      id: this.id
    }), [])
    this.t = t

    if (Platform.OS == "web") {
      useEventListener(window, "mouseup", this.tt.onWindowMouseUp)
    }

    useEffect(() => {
      shared.active[this.id] = this.modalContextValue
      events.emit("modal-added", {id: this.id, data: this.modalContextValue})

      return () => {
        delete shared.active[this.id]
        events.emit("modal-removed", {id: this.id, data: this.modalContextValue})
      }
    }, [])
  }

  render() {
    const {active, children, className, maxWidth, onBack, onRequestClose, wide, ...restProps} = this.props
    const {modalContextValue, t} = this.tt

    if (!active) return null

    const modalCardStyle = {
      width: "100%",
      maxHeight: "calc(100% - 100px)",
      marginTop: 50,
      marginRight: "auto",
      marginLeft: "auto",
      overflowY: "auto"
    }

    if (wide) {
      modalCardStyle.maxWidth = "calc(100% - 100px)"
    } else {
      modalCardStyle.maxWidth = maxWidth || 800
    }

    return (
      <Modal onBackdropPress={onRequestClose} onRequestClose={onRequestClose} transparent>
        <PortalHost name="body">
          <ModalContext.Provider value={modalContextValue}>
            <View
              dataSet={{class: classNames("components--modal", className)}}
              style={{position: "fixed", zIndex: 999, width: "100%", height: "100%", background: "rgba(0 0 0 / 70%)"}}
              {...restProps}
            >
              <Card
                cardRef={this.tt.cardRef}
                className="modal-card"
                style={modalCardStyle}
              >
                <View style={{width: "100%"}}>
                  <View style={{alignSelf: "start"}}>
                    {onBack &&
                      <Pressable onPress={onBack} style={{position: "absolute", top: -30, left: -50}}>
                        <Text style={{whiteSpace: "nowrap"}}>
                          &larr; {t(".go_back")}
                        </Text>
                      </Pressable>
                    }
                  </View>
                  <View style={{alignSelf: "end"}}>
                    {onRequestClose &&
                      <Pressable onPress={onRequestClose} style={{position: "absolute", top: -30, right: -50}}>
                        <Text>
                          &#x2715;
                        </Text>
                      </Pressable>
                    }
                  </View>
                </View>
                {children}
              </Card>
            </View>
          </ModalContext.Provider>
        </PortalHost>
      </Modal>
    )
  }

  onWindowMouseUp = (e) => {
    // Don't request close if the clicked element is a Haya Select option
    // Don't request close if the clicked element is a ck-body-wrapper, which could be because the user is using a CKEditor inside a modal which shouldnt close
    if (parentElement({element: e.target, callback: this.tt.parentElementCallback})) return

    if (this.tt.cardRef.current && !this.tt.cardRef.current.contains(e.target) && this.props.onRequestClose) {
      this.p.onRequestClose()
    }
  }

  parentElementCallback = ({element}) => {
    const dataClass = element.dataset?.class

    // 'options-container' is from HayaSelect
    return dataClass?.includes("options-container") || element.classList?.contains("ck-body-wrapper")
  }
}))
