const localeToCountryCode = {
  "da": "Danish",
  "en": "English",
  "no": "Norwegian",
  "se": "Swedish"
}

export default class Locales {
  static availableLocales() {
    return ["da", "en", "no", "se"]
  }

  static labelForLocale(locale) {
    return digg(localeToCountryCode, locale)
  }
}
