export default memo(shapeComponent(class ComponentsLayoutLoadingSpinner extends ShapeComponent {
  static propTypes = propTypesExact({
    color: PropTypes.string
  })

  render() {
    const {color} = this.props

    return (
      <div className="fa-3x" style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
        <i className="fas fa-spinner fa-spin" style={{color}} />
      </div>
    )
  }
}))
